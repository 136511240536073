@tailwind base;

@layer base {
  html {
    box-sizing: border-box;
    color: theme('colors.brand.white');
    overflow-x: hidden;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #FAFAFA;
    -webkit-box-shadow: 0 0 0 30px #1E1E1E inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  h1 {
    @apply text-2xl leading-normal;
  }

  h2 {
    @apply text-xl leading-normal;
  }

  h3 {
    @apply text-lg leading-normal;
  }

  h4 {
    @apply text-base leading-normal;
  }

  h5  {
    @apply text-sm leading-normal;
  }

  h6 {
    @apply text-xs leading-normal;
  }
}

@tailwind components;
@tailwind utilities;

.grecaptcha-badge {
  visibility: hidden;
}
